.container-header {
  background-color: #1761b9;
  color: #fff;
  font-size: 20px;
  padding: 20px 0;
}
.login {
  .MuiFormControl-fullWidth {
    padding-top: 5px !important;
    // padding-bottom: 10px !important;
    border-bottom: 1px solid #ececec !important;
  }

  .MuiInputLabel-shrink {
    font-size: 18px !important;
    font-weight: bold !important;
    color: #c5c5d5;
  }
  .infoContainer {
    width: 450px;
    margin-right: 50px;

    .MuiInputBase-input {
      padding: 5px !important;
      font-weight: bold !important;
      font-size: 14px !important;

    }
    .MuiFormLabel-root.Mui-focused{
      color:#c5c5d5 !important;
    }
  }
  .MuiButton-fullWidth {
    border-radius: 5px !important;
  }

  .carousal {
    // position: absolute;
    // left: 200px;
    // bottom: 250px;
    // bottom: 25%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    min-height: 150px;
    h1 {
      font-size: 3.5rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
  .Mui-disabled {
    background-color:rgba(0, 0, 0, 0.12) !important;
  }
}
