.dashboard {
  .content-body {
    padding: 2em;
  }
}
.dialogActions {
  .Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}
