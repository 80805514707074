@import './theme-variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.app-spendplan{
    @extend :root;
    --gray: #f6f6fc;
    --ball-blue:#222222;
    --white:#fff;
    --black:#000000;
    --peridot:#E6DB74;
    .header-app-title{
        font-family: "Roboto";
        font-weight: 500;
    }
    .side-navigation{
        .side-navigation-drawer{
            .MuiListItemIcon-root{
                min-width: 63px !important;
            }
            .MuiDrawer-paper{
                background: rgb(4,125,216);
                position: fixed;
                top: 70px;
                // width: 60px; //IT will not allow toggle the menu   
            }
            .side-navigation-toolbar{
                .MuiIconButton-root{
                    color: #fff;
                    padding: 16px;
                }
                
            }
            .MuiList-padding{
                .MuiButtonBase-root{
                    .MuiListItemIcon-root{
                        color: #fff;
                    }
                }
                color: #fff;
            }
        }
        .MuiListItem-gutters{
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 10px;
        }
    }
    main{
        // margin-left: 60px;
        // background: #fff;
        .app--dashboard--main--page{
            margin-top: 70px;
            margin-bottom: 35px;
        }
    }
    .footer-copyright{
        margin-left: 60px;
    }
    &--wrapper--body{
        // background: var(--white) !important;
		padding: 20px;
    }
    #PageDesignerCard1-wrapper{
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 5px 15px #ddd;
        padding: 20px;
        .linkTitle{
            font-size: 18px !important;
            font-weight: bold;
        }
        .card-title-links{
            width: fit-content;
            margin-left: 25px;
            a{
                font-weight: bold;
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
                padding-left: 3px !important;
                div{
                    span{
                        font-weight: bold;
                    }
                }
                &:hover{
                    background: transparent !important;
                    color: var(--black);
                }
            }
        }
    }
    .renderer-new-table{
        height: auto;
        background: transparent;
        .w-full.flex.flex-col.report-parent-div{
            overflow: hidden;
        }
        .table-general .MuiTableSortLabel-root:hover{
            color: #000 !important;
        }
        .general-table-heading{
            // background: transparent;
            // color: #000;
            // margin-bottom: 20px;
            // border-bottom: 1px solid #ddd;
            // span{
            //     color: #047dd8;
            //     font-size: 20px !important;
            //     font-weight: bold;
            // }
        }
        table{
            tr[style]{
                background: #fff !important;
                td[style]{
                    background: transparent !important;
                    color: #000 !important;
                }
                &:nth-child(even){
                    background: #f3f3f3 !important;
                }
            }
        }
    }
    &--wrapper--content{
        // padding-top: 70px;
        .app--dashboard--cards{
            // background: #75646494;
            color: var(--black);
            border-bottom: 3px solid #00c7ff;
            h5{
                color: var(--black);
                margin: 0;
                font-size: 15px;
            }
            h6{
                margin: 0;
                
            }
            &--content{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 1px;
                border-bottom: 1px solid #eee;
                &--main-heading{
                    display: inline-block;
                    color: var(--black);
                    margin: 0;
                }
                &--status{
                    color: var(--black);
                    font-weight: normal;
                    display: inline-block;
                    float: right;
                    margin: 0;
                    font-size: 14px;
                }
                &--title--heading{
                    text-Align: left;
                    margin-bottom: 5px;
                    font-size:1.3rem;
                    text-transform: capitalize;
                }
                &--main--content{
                    text-transform: capitalize;
                }
                &--columns{
                    display: flex;
                    padding: 5px 15px;
		            flex-wrap: wrap;
                    &--column--one{
                        flex: 0;
                        text-align: left;
                        padding-right: 10px;
                        img{
                            width: 50px;
                        }
                    }
                    &--column--two{
                        flex: 2;
                        text-align: left;
                        min-height: 230px;
                    }
                }
                &--footer{
                    width:100%;
                    padding:5px 10px;
                    text-align:right;
                    span{
                        color: #000;
                        text-decoration: underline;
                    }
                }
            }
        }
        .app--dashboard{
            &--404--page{
                background: var(--gray);
                
            }
        }
    }
    .tab-container{
        border-bottom: 1px solid #ddd;
        cursor: pointer;
    }
    .custom--card--link{
        text-decoration: none;
    }
    .app-not-found{
        color: var(--black);
        box-shadow: inset 0px 0px 4px 0px #ddd;
        border: 10px solid #102d5e;
        display: flex;
        justify-content: center;
        top: 17%;
        height: 300px;
        left: 50%;
        width: 800px;
        margin-left: -400px;
        position: absolute;
        h4{
            color: var(--black);
        }
    }
    .app--dashboard--main--page{
        #componentViewPaneElement{
            width: 100%;
            min-height: 100vh;
            height: 100%;
            background-color: #eeeeee;
            .react-grid-layout{
                width: 100%;
                position: relative;
                transition: height 200ms ease;
                // background-color: #fff;
                .card-header-title{
                    color: #fff;
                    background: #0094ff;
                    .MuiTypography-h5{
                        font-size: 18px;
                    }
                }
                .graph--card--content{
                    height: 75%;
                }
            }
            .chartEqualHeight{
                height: 100%;
                width: 100%;
            }
        }
    }
    .table-row-tr{
        background-color: #181818;
        color:#fff;
		border-Top: 1px solid #282828;
    }
    .btn-new-btn{
        float:right; 
        clear:both; 
        margin: 0px 50px 20px 0px; 
        font-size: inherit;
    }

    //Newly added for demo
    .mb-20-p {
        margin-bottom: 20px;
    }
    .black-text {
        color: #000;
    }
    .navigation {
        margin-top: 0px;
        background-color: #fff;
        ul {
            box-shadow: #fff 2px 15px 20px 20px inset;
        }
    }
	.background-theme {
		padding: 0.5rem 0 !important;
		background-color: #fff;
		box-shadow: none;
	}
    .app-header {
        background-color: #fff !important;
        // border-bottom: 1px solid #102d5e;
        position: fixed !important;
        top: 0;
        // width: 60px;
    }
    .app-bar {
		color: #000;
		// background-color: #fff;
		top: 0;
		left: auto;
		right: 0;
		position: fixed;
		width: 100%;
		display: flex;
		z-index: 1;
		box-sizing: border-box;
		flex-shrink: 0;
		flex-direction: column;
		box-shadow: 0px 2px 4px - 1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	}
    .custom-table-pagination{
        background-color: #fff;
        color: #000;
    }
    .report-parent-div{
        .MuiIconButton-root{
            color: #000;
        }
        .MuiIconButton-root.Mui-disabled {
            color: #eeeeee;
        }
        .MuiSelect-icon {
            color: #000;
        }
    }

    //Newly added for demo
    .mb-20-p {
        margin-bottom: 20px;
    }
    .black-text {
        color: #000;
    }
    .navigation {
        margin-top: 0px;
        background-color: #fff;
        ul {
            box-shadow: #fff 2px 15px 20px 20px inset;
        }
    }
    .background-theme {
        padding: 0.5rem 0 !important;
        background-color: #fff;
        box-shadow: none;
    }
    .app-header {
        background-color: #fff !important;
        // border-bottom: 1px solid #102d5e;
        // position: unset !important;
    }
    .app-bar {
        color: #000;
        // background-color: #fff;
        top: 0;
        left: auto;
        right: 0;
        position: fixed;
        width: 100%;
        display: flex;
        z-index: 1;
        box-sizing: border-box;
        flex-shrink: 0;
        flex-direction: column;
        box-shadow: 0px 2px 4px - 1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
    .custom-table-pagination{
        background-color: #fff;
        color: #000;
    }
    .report-parent-div{
        .MuiIconButton-root{
            color: #000;
        }
        .MuiIconButton-root.Mui-disabled {
            color: #eeeeee;
        }
        .MuiSelect-icon {
            color: #000;
        }
    }
    .setup-data-headings{
        font-size: 30px;
        font-weight: bold;
        &.active-global-link{
            border-bottom: 4px solid #047dd8;
        }
    }
    .setup-data-headings{
        font-size: 25px !important;
        font-weight: bold;
        line-height: 1;
        span{
            font-size: 16px !important;
            font-weight: bold;
        }
        &.active-global-link{
            border-bottom: 4px solid #047dd8;
            color:#047dd8;
        }
        // float: right;
    }
    .setup-data-headings-align-right{
        // float: left;
        text-align: right;
    }
    .setup-data-headings-select{
        min-width: 100px;
    }
    .justify-content{
        justify-content: flex-end;
    }
    .upload{
        margin-left: 8px;
		margin-right: 8px;
		width: 200;
    }
    .upload-btn{
        background: transparent;
        border:1px solid #047dd8;
        font-weight: bold;
        color: #047dd8;
    }
    .MuiTableContainer-root{
        max-width: 90vw;
        .MuiTableCell-root{
            padding: 5px 8px;
            line-height: 1;
            span{
                line-height: 1;
            }
        }
    }
    .filter-container{
        padding: 12px;
        &.MuiGrid-container{
            .MuiGrid-item{
                padding: 0 5px;
            }
        }
        .custom-select{
            border:2px solid #ddd;
            border-radius: 8px;
            &:focus{
                background: transparent;
                outline: none;
            }
        }
        .MuiSelect-select{
            padding-left: 10px;
            line-height: 2;
            &:focus{
                background: transparent;
            }
        }
    }
    .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
        background: transparent;
    }
    .MuiSelect-select.MuiSelect-select{
        font-weight: bold;
    }
}