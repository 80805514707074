@import './_global.scss';
@import './_variables.scss';
@import './theme-variables';
@import './theme-spendplan';
@import './theme-cvm';

$right: right;
$borderColor: #e4e4e4;
$primary: #1761b9;
// @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
$roboto-sans: 'Roboto', sans-serif;

/******************Media Quireies***********************/
$break-small: 768px;

/*App Dashboard*/
//
/**** LOGIN PAGE CSS *****/
/**** LOGIN PAGE CSS *****/
body {
  padding: 0px;
  margin: 0px;
  // background-color: #f6f6fc !important;
  color: #1b2a47 !important;
  font-family: $roboto-sans !important;
  color: #1b2a47;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 9px; // default is around 15px
    height: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: '#cdcdcd';
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: '#7c7d7d';
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background: transparent;
    border-radius: 10px;
  }
}

.login-sec {
  background: url('../images/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 100px 15px;
  height: calc(100vh - 200px);
  overflow: hidden;
  // height: calc(100vh);
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
}

.MuiButton-containedPrimary {
  background-color: #1761b9 !important;
}
.MuiButton-outlinedPrimary {
  border-color: #1761b9 !important;
  border-width: 2px !important;
}

.Toastify__toast--info {
  background: $primary;
}

