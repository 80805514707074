@import './theme-variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.app-cvm {
    @extend :root;
    --gray: #f6f6fc;
    --ball-blue: #222222;
    --white: #fff;
    --black: #000000;
    --peridot: #E6DB74;

    .header-app-title {
        font-family: "Roboto";
        font-weight: 500;
    }

    .side-navigation {
        .side-navigation-drawer {
            .MuiListItemIcon-root {
                min-width: 63px !important;
            }

            .MuiDrawer-paper {
                background: rgb(4, 125, 216);
                position: fixed;
                top: 70px;
                // width: 60px; //IT will not allow toggle the menu
            }

            .side-navigation-toolbar {
                .MuiIconButton-root {
                    color: #fff;
                    padding: 16px;
                }

            }

            .MuiList-padding {
                .MuiButtonBase-root {
                    .MuiListItemIcon-root {
                        color: #fff;
                    }
                }

                color: #fff;
            }
        }

        .MuiListItem-gutters {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 10px;
        }
    }

    main {

        // margin-left: 60px;
        // background: #fff;
        .app--dashboard--main--page {
            margin-top: 70px;
            margin-bottom: 35px;
        }
    }

    .footer-copyright {
        margin-left: 60px;
    }

    &--wrapper--body {
        // background: var(--white) !important;
        padding: 20px;
    }

    #PageDesignerCard1-wrapper {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 5px 15px #ddd;
        padding: 20px;

        .linkTitle {
            font-size: 18px !important;
            font-weight: bold;
        }

        .card-title-links {
            width: fit-content;
            margin-left: 25px;

            a {
                font-weight: bold;
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
                padding-left: 3px !important;

                div {
                    span {
                        font-weight: bold;
                    }
                }

                &:hover {
                    background: transparent !important;
                    color: var(--black);
                }
            }
        }
    }

    .renderer-new-table {
        height: auto;
        background: transparent;

        .w-full.flex.flex-col.report-parent-div {
            overflow: hidden;
        }

        .table-general .MuiTableSortLabel-root:hover {
            color: #000 !important;
        }

        .general-table-heading {
            // background: transparent;
            // color: #000;
            // margin-bottom: 20px;
            // border-bottom: 1px solid #ddd;
            // span{
            //     color: #047dd8;
            //     font-size: 20px !important;
            //     font-weight: bold;
            // }
        }

        table {
            tr[style] {
                background: #fff !important;

                td[style] {
                    background: transparent !important;
                    color: #000 !important;
                }

                &:nth-child(even) {
                    background: #f3f3f3 !important;
                }
            }
        }
    }

    &--wrapper--content {

        // padding-top: 70px;
        .app--dashboard--cards {
            // background: #75646494;
            color: var(--black);
            border-bottom: 3px solid #00c7ff;

            h5 {
                color: var(--black);
                margin: 0;
                font-size: 15px;
            }

            h6 {
                margin: 0;

            }

            &--content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 1px;
                border-bottom: 1px solid #eee;

                &--main-heading {
                    display: inline-block;
                    color: var(--black);
                    margin: 0;
                }

                &--status {
                    color: var(--black);
                    font-weight: normal;
                    display: inline-block;
                    float: right;
                    margin: 0;
                    font-size: 14px;
                }

                &--title--heading {
                    text-Align: left;
                    margin-bottom: 5px;
                    font-size: 1.3rem;
                    text-transform: capitalize;
                }

                &--main--content {
                    text-transform: capitalize;
                }

                &--columns {
                    display: flex;
                    padding: 5px 15px;
                    flex-wrap: wrap;

                    &--column--one {
                        flex: 0;
                        text-align: left;
                        padding-right: 10px;

                        img {
                            width: 50px;
                        }
                    }

                    &--column--two {
                        flex: 2;
                        text-align: left;
                        min-height: 230px;
                    }
                }

                &--footer {
                    width: 100%;
                    padding: 5px 10px;
                    text-align: right;

                    span {
                        color: #000;
                        text-decoration: underline;
                    }
                }
            }
        }

        .app--dashboard {
            &--404--page {
                background: var(--gray);

            }
        }
    }

    .tab-container {
        border-bottom: 1px solid #ddd;
        cursor: pointer;
    }

    .custom--card--link {
        text-decoration: none;
    }

    .app-not-found {
        color: var(--black);
        box-shadow: inset 0px 0px 4px 0px #ddd;
        border: 10px solid #102d5e;
        display: flex;
        justify-content: center;
        top: 17%;
        height: 300px;
        left: 50%;
        width: 800px;
        margin-left: -400px;
        position: absolute;

        h4 {
            color: var(--black);
        }
    }

    .app--dashboard--main--page {
        #componentViewPaneElement {
            width: 100%;
            min-height: 100vh;
            height: 100%;
            background-color: #eeeeee;

            .react-grid-layout {
                width: 100%;
                position: relative;
                transition: height 200ms ease;

                // background-color: #fff;
                .card-header-title {
                    color: #fff;
                    background: #0094ff;

                    .MuiTypography-h5 {
                        font-size: 18px;
                    }
                }

                .graph--card--content {
                    height: 75%;
                }
            }

            .chartEqualHeight {
                // height: 100%;
                // width: 100%;
            }
        }
    }

    .table-row-tr {
        background-color: #181818;
        color: #fff;
        border-Top: 1px solid #282828;
    }

    .btn-new-btn {
        float: right;
        clear: both;
        margin: 0px 50px 20px 0px;
        font-size: inherit;
    }

    //Newly added for demo
    .mb-20-p {
        margin-bottom: 20px;
    }

    .mb-5 {
        margin-bottom: 5px;
    }

    .ml-5 {
        margin-left: 5px;
    }

    .mr-5 {
        margin-right: 5px;
    }

    .black-text {
        color: #000;
    }

    .navigation {
        margin-top: 0px;
        background-color: #fff;

        ul {
            box-shadow: #fff 2px 15px 20px 20px inset;
        }
    }

    .background-theme {
        padding: 0.5rem 0 !important;
        background-color: #fff;
        box-shadow: none;
    }

    .app-header {
        background-color: #fff !important;
        // border-bottom: 1px solid #102d5e;
        position: fixed !important;
        top: 0;
        // width: 60px;
    }

    .app-bar {
        color: #000;
        // background-color: #fff;
        top: 0;
        left: auto;
        right: 0;
        position: fixed;
        width: 100%;
        display: flex;
        z-index: 1;
        box-sizing: border-box;
        flex-shrink: 0;
        flex-direction: column;
        box-shadow: 0px 2px 4px - 1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .custom-table-pagination {
        background-color: #fff;
        color: #000;
    }

    .report-parent-div {
        .MuiIconButton-root {
            color: #000;
        }

        .MuiIconButton-root.Mui-disabled {
            color: #eeeeee;
        }

        .MuiSelect-icon {
            color: #000;
        }
    }

    //Newly added for demo
    .mb-20-p {
        margin-bottom: 20px;
    }

    .black-text {
        color: #000;
    }

    .navigation {
        margin-top: 0px;
        background-color: #fff;

        ul {
            box-shadow: #fff 2px 15px 20px 20px inset;
        }
    }

    .background-theme {
        padding: 0.5rem 0 !important;
        background-color: #fff;
        box-shadow: none;
    }

    .app-header {
        background-color: #fff !important;
        // border-bottom: 1px solid #102d5e;
        // position: unset !important;
    }

    .app-bar {
        color: #000;
        // background-color: #fff;
        top: 0;
        left: auto;
        right: 0;
        position: fixed;
        width: 100%;
        display: flex;
        z-index: 1;
        box-sizing: border-box;
        flex-shrink: 0;
        flex-direction: column;
        box-shadow: 0px 2px 4px - 1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .custom-table-pagination {
        background-color: #fff;
        color: #000;
    }

    .report-parent-div {
        .MuiIconButton-root {
            color: #000;
        }

        .MuiIconButton-root.Mui-disabled {
            color: #eeeeee;
        }

        .MuiSelect-icon {
            color: #000;
        }
    }

    .setup-data-headings {
        font-size: 30px;
        font-weight: bold;

        &.active-global-link {
            border-bottom: 4px solid #047dd8;
        }
    }

    .setup-data-headings {
        font-size: 25px !important;
        font-weight: bold;
        line-height: 1;

        span {
            font-size: 16px !important;
            font-weight: bold;
        }

        &.active-global-link {
            border-bottom: 4px solid #047dd8;
            color: #047dd8;
        }

        // float: right;
    }

    .setup-data-headings-align-right {
        // float: left;
        text-align: right;
    }

    .setup-data-headings-select {
        min-width: 100px;
    }

    .justify-content {
        justify-content: flex-end;
    }

    .upload {
        margin-left: 8px;
        margin-right: 8px;
        width: 200;
    }

    .upload-btn {
        background: transparent;
        border: 1px solid #047dd8;
        font-weight: bold;
        color: #047dd8;
    }

    .MuiTableContainer-root {
        // max-width: 90vw;  // removed this, so that tables take full width.

        .MuiTableCell-root {
            padding: 10px;
            line-height: 1;

            span {
                line-height: 1;
            }
        }
    }

    .filter-container {
        padding: 12px;

        &.MuiGrid-container {
            .MuiGrid-item {
                padding: 0 5px;
            }
        }

        .custom-select {
            border: 2px solid #ddd;
            border-radius: 8px;

            &:focus {
                background: transparent;
                outline: none;
            }
        }

        .MuiSelect-select {
            padding-left: 10px;
            line-height: 2;

            &:focus {
                background: transparent;
            }
        }
    }

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background: transparent;
    }

    .MuiSelect-select.MuiSelect-select {
        font-weight: bold;
    }

    //Signup
    .container-header {
        background-color: #1761b9;
        color: #fff;
        font-size: 20px;
        padding: 20px 0;
    }

    .signup {
        .MuiFormControl-fullWidth {
            padding-top: 5px !important;
            padding-bottom: 10px !important;
            border-bottom: 1px solid #ececec !important;
        }

        .MuiInputLabel-shrink {
            font-size: 18px !important;
            font-weight: bold !important;
            color: #c1c1d2;
        }

        .infoContainer {
            width: 450px;
            margin-right: 50px;

            // .MuiInputBase-input {
            //   padding: 10px 0 0 0 !important;
            //   font-weight: bold !important;
            // }
            .MuiInputBase-input {
                padding: 10px 0 0 0 !important;
                font-weight: bold !important;
                font-size: 14px !important;
            }

            .MuiFormLabel-root.Mui-focused {
                color: #c5c5d5 !important;
            }
        }

        .MuiButton-fullWidth {
            border-radius: 5px !important;
        }

        .MuiFormControlLabel-root {
            margin-top: 5px !important;
            margin-bottom: 20px !important;
        }

        .carousal-container {
            position: absolute;
            left: 200px;
            bottom: 0px;
            height: 100vh;
            display: flex;
            align-items: center;

        }

        .carousal {
            // position: absolute;
            // left: 200px;
            // bottom: 250px;
            // bottom: 25%;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 150px;

            h1 {
                font-size: 3.5rem;
            }

            p {
                font-size: 1.5rem;
            }
        }

        .MuiFormControlLabel-label {
            color: #000;
            font-weight: bold !important;
        }

        .Mui-disabled {
            background-color: rgba(0, 0, 0, 0.12) !important;
        }

        .selected-carousal {
            background-color: #1761b9;
            width: 12px;
            height: 12px;
            margin-right: 10px;
            border-radius: 100px;
            border: 1.5px solid #fff;
        }

        .un-selected-carousal {
            background-color: #B3A698;
            width: 12px;
            height: 12px;
            margin-right: 15px;
            border-radius: 100px;
            border: 1.5px solid #B3A698;
        }

        .signup-paper {
            color: rgba(0, 0, 0, 0.54);
            padding: 30px 50px;
            border-radius: 0;
        }

        .signup-header {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
            background-color: #1761b9;
            text-align: center;
            color: #fff;
            padding: 20px;
        }

        .signup-title {
            font-weight: bold;
            color: #002744;
        }

        .signup-input-field {
            margin: 20px 0;
            padding: 0;
            border-bottom: 2px;
        }

        .signup-icon {
            color: #C5C5D5;
            font-size: 20px;
        }

        .error-message {
            color: #ff0000;
            font-size: 14px;
        }

        .link {
            text-decoration: none;
        }
    }

    .MuiRadio-root {
        color: #1761b9 !important;
    }

    .contract-annotations {
        span.MuiStepLabel-root.MuiStepLabel-horizontal.Mui-disabled.MuiStepLabel-alternativeLabel {
            background: #fff !important;
        }
    }

    .action-icons {
        font-size: 20px;
        margin-left: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    .MuiAccordionDetails-root {
        display: block;
    }

    .contract-insight-container {
        margin-top: 5px;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .heading-3 {
        font-size: 12px;
    }

    .heading-2 {
        background-color: #ffc404;
        font-weight: bold;
        padding: 8px 15px;
    }

    .insight-card {
        border-radius: 15px;
        padding: 20px 20px 0 20px;
        background-color: rgba(4, 125, 216, 0.1);

        .file-name {
            overflow-wrap: break-word;
            margin-bottom: 10px;
        }

        .icon-box {
            justify-content: flex-end;

            .icon {
                padding: 5px;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }

    .insight-info {
        .insight-info-item {
            margin-top: 15px;
            justify-content: center;

            .title {
                color: white;
                padding: 5px 15px;
                width: 70%;
                text-align: center;
                margin-bottom: 15px;
            }

            .icon {
                font-size: 55px;
            }

            .dsc {
                width: 50%;
                padding: 10px;
            }
        }
    }

    .contract-tabs {
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;

        button {
            text-transform: capitalize;
        }
    }

    .insight-block {
        height: 200px !important;
        border: 2px solid green;

        legend {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .title {
            border: 1px dashed green;
            padding: 5px 50px;
            white-space: nowrap;
            align-self: center;
        }

        .border-div {
            width: 100%;
            height: 2px;
            background: green;
            margin: auto;
        }

        .termination-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            /* number of lines to show */
            -webkit-box-orient: vertical;
        }
    }

    .right-section {
        .party-container {
            padding-left: 12px;

            .party-item-icon {
                display: flex;
                margin: auto;
                justify-content: center;
            }

            .item {
                border-bottom: 1px dashed black;
                padding-top: 15px;

                .party-item {
                    margin-bottom: 15px;
                }
            }
        }

    }

    .search-buttons {
        .MuiButton-root {
            min-width: 130px;
        }
    }

    .layout {
        .contract-tabs {
            .MuiAppBar-colorPrimary {
                background: #eee !important;
                color: black;
            }
        }
    }

    .login-ciep {
        .carousal {
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 150px;
            position: absolute;
            left: 20%;
            top: 45%;
        }
    }


    /** Newly added for businessrule **/
    .rules {
        .MuiFormControl-root.flex.items-center.max-w-full.datasourceList-page {
            margin-bottom: 20px;
        }

        div#componentDescriptionPane .MuiFormGroup-root.items-center.max-w-full.MuiFormGroup-row div {
            margin-left: 0px;
            margin-bottom: 0px;
        }

        div#componentDescriptionPane .p-16 form .MuiFormGroup-root.items-center.max-w-full.MuiFormGroup-row .MuiFormControl-root {
            padding: 0px;
            margin-bottom: 10px;
        }

        form .MuiFormControl-root label.MuiFormLabel-root,
        form .MuiFormGroup-root label.MuiFormLabel-root {
            font-weight: bold;
            color: #222;
        }

        .MuiFormControl-root.MuiTextField-root.mt-8.mb-16.MuiFormControl-fullWidth {
            padding: 5px 0px;
            margin: 5px 0px;
        }

        form .MuiFormLabel-root {
            padding-top: 10px;
            padding-right: 10px;
        }

        .MuiInputBase-input {
            min-height: 1.959em !important;
        }

        form {
            .MuiFormLabel-root {
                margin-bottom: 0.5rem;
            }

            .MuiFormControl-root {
                margin-bottom: 1.5rem;
            }

            .MuiOutlinedInput-root {
                border-radius: 0px;
            }

            input {
                padding: 7px !important;
            }
        }

        form .max-w-full {
            width: 100%;
        }
    }


    /** Newly added for businessrule **/

    .recent-apps {
        h4 {
            // margin-bottom: 15px;
            padding: 10px 0px;
            font-size: 35px;
        }

        .bdr-t {
            border-top: 1px solid #e4e4e4;
        }
    }

    .recent-apps-bannerbg {
        background: url('../images/recentappbanner.png') no-repeat;
        max-height: 200px;
        padding: 10px 20px !important;
        color: $white;
        background-size: cover;
        background-position: center;
    }

    .recent-apps-card {

        .MuiPaper-rounded {
            padding: 10px;
            border-bottom: 3px solid #00c7ff;
        }

        h6 {
            padding: 10px 0px;
            font-size: 16px;
            color: #1B2A47;
            font-weight: 600;
        }

        img {
            // max-width: 50px;
        }

    }

    @media screen and (min-width:600px) {
        .recent-apps-card .MuiGrid-grid-xs-3 {
            min-height: 132px;
        }
    }

    .recent-apps-bannerbg h6 {
        font-size: 1.5rem;
    }

    .recent-apps {
        // margin-top: 64px;
        width: 100%;
        margin-bottom: 30px;

        .downmenu.navmenu-bar {
            .MuiListItem-root {
                width: auto;
            }

            .menu-nav {
                flex-wrap: wrap;

                .menulists {
                    margin-bottom: 10px;
                }
            }
        }

        .headerbtm-sec {
            .houseicon {
                color: #00C7FF;
                font-size: 20px;
            }

            .keywordtxt {
                color: #1B2A47;
                padding-left: 16px;
                border-left: 1px solid #E4E4E4;
                font-size: 16px;
            }
        }

        .navmenu-bar {
            .menuleft {
                justify-content: flex-end;
            }
        }

        .flex {
            display: flex;
            align-items: center;

        }

        .menu-nav {
            display: flex;

            .menulists {
                color: #111;
                padding: 0px 15px;
                font-size: 16px;

                .MuiListItemIcon-root,
                .MuiListItemAvatar-root {
                    min-width: 0px !important;
                }

                .hamburgerimg {
                    width: 33px;
                }

                .Addapp-txtfield .MuiOutlinedInput-input,
                .Addapp-txtfield .MuiSelect-outlined.MuiSelect-outlined {
                    padding: 3px 14px;
                    width: 180px;
                    background-color: #F6F6FC;
                    font-size: 14px;
                }


            }

        }

        .menu-left {
            .menu-nav {
                .menu-lists {
                    justify-content: flex-end;

                    .MuiOutlinedInput-root {
                        width: 100%;
                    }

                    svg.MuiSvgIcon-root.notifyicon {
                        font-size: 20px;
                    }
                }
            }
        }

        .menu-nav .addnewappbtn {
            min-width: 148px;
            padding-bottom: 5px;
        }

        .menulists.profile-n {
            min-width: 175px;
        }

    }

    .pxy20 {
        padding: 20px;
    }

    .pl-20 {
        padding-left: 20px;
    }

    .ptl-20 {
        padding: 20px 0px 0px 20px;
    }

    .pb-10 {
        padding-bottom: 10px;
    }

    .recent-apps-card .MuiPaper-rounded p {
        margin-bottom: 8px;
    }

    .recent-apps-card .MuiPaper-rounded>p {
        font-weight: 600;
    }

    .MuiPaper-elevation1 {
        border: none;
        border-radius: 0px;
    }

    .data-analyst-view {
        .contract-tabs {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .data-analyst-tabs {
            .MuiTabs-root {
                min-height: 35px;
            }

            .MuiTab-root {
                padding: 0px 12px;
                min-height: 30px;
            }

            .MuiIconButton-root {
                padding: 5px;
            }
        }

        // .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        // padding: 5px;
        // }
        .MuiInputBase-input {
            // padding: 2px 0 2px;
            font-size: 0.875rem;

            p {
                font-size: 0.84rem;
            }
        }

        .MuiFormLabel-root {
            font-size: 0.85rem;
        }

        .MuiGrid-grid-xs-3 {
            font-size: 0.875rem;
        }
    }

    .complex-view .contract-tabs {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .react-virtualized-menu-placeholder {
        margin-top: 5px;
        color: #9a9a9a;
        text-align: center;
    }

    .react-virtualized-list-wrapper li {
        list-style: none;
    }

    .MuiTreeItem-group {
        margin-left: 14px;
        padding-left: 18px;
        border-left: 1px dashed black;
    }

    .MuiTreeItem-label {
        width: 100%;
        min-width: 0px;
        // padding-left: 4px;
        position: relative;
        letter-spacing: 0.15px;
        font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.5;
        padding: 5px 10px;
        margin-left: 5px;
    }

    .MuiTreeItem-content {
        padding: 0px 8px;
        // margin: 5px 2px;
        margin: 10px 0px 0px 0px;
    }

    .MuiTreeItem-iconContainer {
        svg {
            font-size: 30px;
            padding-left: 3px;
        }
    }

}