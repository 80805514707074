.container {
	display: block;
	/* flex-direction: column; */
	justify-content: center;
	align-items: center; 
	text-align: center;
	height: 100%;
}

.input-container {
	align-items: center;
	background-color: #EDEDED;
	display: flex;
	width: -webkit-fill-available;
	position: fixed;
	bottom: 0;
	z-index: 1250;
	/* left: 0; */
	/* justify-content: center; */
	/* margin-bottom: 20px; */
	/* margin-left: 30%; */

}
.MuiDrawer-paperAnchorDockedRight{
	margin-top: 70px;
	margin-bottom: 70px;
}

.dialog-title {
	background-color: #002744 !important;
	color: #fff;
  }

  .dialog-header-colour {
	background-color: #265374 !important;
	color: #fff !important;
  }

  .commentCard {
	margin: 10px 0;
	padding: 10px;
	background-color: #f5f5f5; /* light gray */
  }
  
  .commentText {
	margin-bottom: 10px;
  }
  
  .commentBy {
	font-style: italic;
  }

.styled-input {
	width: 650px;
	padding: 12px 60px 12px 20px;
	/* increase padding on the right to make space for the button */
	border: 1px solid rgb(143, 142, 142);
	/* border-radius: 10px 0px 0px 10px; */
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
	transition: box-shadow 0.3s ease-in-out;
	outline: none;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
}

.send-button {
	padding: 13px 20px;
	background-color: #3f51b5;
	color: #fff;
	border: none;
	/* border-radius: 0px 10px 10px 0px; */
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	flex-grow: 2;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.send-button:hover {
	background-color: #1a237e;
}


.chart {
	/* padding: 0px 20px; */
	/* width: 500px; */
	margin-bottom: 10px;
	cursor: pointer;
}

.overlap-component {
	position: fixed;
	top: 0;
	left: 0;
	width: 250px;
	height: 100%;
	background-color: rgb(46, 46, 46);
	margin: 2px;
	border-radius: 5px;
	/* additional styles */

}

.overlay-content {
	display: inline-flexbox;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 20px;
	color: white;
	/* additional styles */
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;

}

.overlay-content button {
	background-color: transparent;
	color: white;
	cursor: pointer;
	border: none;
	text-align: left;
	/* additional styles */
}

.left-items {
	padding: 18px 14px;
	border-radius: 4px;
	font-size: 16px;
	transition-duration: 0.4s;
	align-content: start;
	width: 190px;

}

.left-items:hover {
	background-color: rgb(122, 122, 122);
	color: #ffffff;
}

.chat-container {
	/* overflow: auto; */
	height: 100%;
	display: flex;
	flex-direction: column;
	scroll-behavior: smooth;
	width: 50em;
	/* margin-left: 100px; */
	margin: auto;
	margin-bottom: 70px !important;
}

.message {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* margin: 10px; */
	padding: 10px;
	/* max-width: 80%; */
	height: fit-content;
	/* border-radius: 10px; */
}

.user-message {
	align-self: center;
	font-size: 20px;
	font-weight: bold;
	/* background-color: #353434;
	color: white;
	border-radius: 15px 0px 15px 15px !important; */
}

.bot-message {
	align-items: center;
	background-color: #fefefe;
	/* border-radius: 0px 15px 15px 15px !important; */
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
	/* margin-bottom: 1rem; */
}

.bot-message-border {
	align-self: center;
	background-color: #fefefe;
	/* border: 1px solid; */
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
	/* margin-bottom: 1rem; */
}

.user {
	font-weight: bold;
	margin-bottom: 5px;
}

.bot {
	font-weight: bold;
	margin-bottom: 5px;
}


.typing-effect::after {
	content: "";
	display: inline-block;
	width: 0.2em;
	height: 1em;
	vertical-align: bottom;
	animation: blink 0.7s infinite;
	color: #333;
}

@keyframes blink {
	50% {
		opacity: 0;
	}
}

.workSpaceBar-component {
	position: fixed;
	top: 0;
	right: 0;
	width: 250px;
	height: 100%;
	background-color: rgb(46, 46, 46);
	margin: 2px;
	border-radius: 5px;
	/* additional styles */

}

.workspace {
	width: auto;
	height: auto;
	background-color: rgb(198, 219, 226);
	border-radius: 10px;
	border: 3px solid grey;
	position: absolute;
	cursor: move !important;
	top: 10px;
	left: 10px;
}
