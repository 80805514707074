.layout {
  .MuiAppBar-root {
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .MuiAppBar-colorPrimary {
    background-color: #002744 !important;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgba(229, 229, 229, 0.5);
    margin-left: 20px;
    padding: 16px 0 16px 20px;
  }

  .header-right {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 8px 26px;
      li {
        list-style: none;
        margin-left: 25px;
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        &:last-child {
          border-left: 1px solid rgba(229, 229, 229, 0.5);
          line-height: normal;
          padding: 8px 0 8px 20px;
        }
        .notification-icon {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 2px;
            width: 7px;
            height: 7px;
            background: #fb4651;
            border-radius: 50%;
          }
        }
        .user-profile {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
        }
        .user-name {
          margin-left: 11px;
          i {
            font-style: normal;
            display: block;
            font-size: 12px;
            margin-top: 4px;
          }
        }
      }
      .search-wrapper {
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;

        .search-input {
          padding: 5px 5px 5px 15px;
          border-right: 1px solid #c4c4c4;
          width: 400px;
        }
      }
    }
  }
  .lists {
    .MuiListItem-gutters {
      // padding-left: 26px !important;
    }

    .MuiListItem-button {
      &:hover {
        background: rgba(4, 125, 216, 0.1) !important;
        border-right: 3px solid #1761b9 !important;
      }
    }
    .MuiListItem-root.Mui-selected {
      background: rgba(4, 125, 216, 0.1) !important;
      border-right: 3px solid #1761b9 !important;
    }
  }
}
