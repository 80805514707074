$blue:#060721;
$Lblue:#00c7ff;
$ornage:#ff9800;
$Dornage:#de8605;
$white: #ffffff;
$gery:#ebebeb;
$black:#000;
$light-green:#E2E21B;
$bgColor:#060606;
$neroBgColor:#222;
$curiousBlue:#2a9fd6;
$neroSecondColor:#181818;

$fontWeightBold:'bold';
$fontWeightNormal:'normal';
$fontWeightBolder:'Bolder';

.font-weight-bold{
  font-weight: $fontWeightBold;
}
.font-weight-normal{
  font-weight: $fontWeightNormal;
}

.font-weight-bolder{
  font-weight: $fontWeightBolder;
}

.blackBg{
  background-color: $bgColor;
}
.neroBg{
  background-color: $neroBgColor;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left !important;
}
.text-center{
  text-align: center;
}
.text-color-white{
  color:$white;
}
$spaceamounts: (0,5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, right, bottom, left,all);
@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m-#{$side}-#{$space} {
        margin: #{$space}px !important;
      }
      .p-#{$side}-#{$space} {
        padding: #{$space}px !important;
      }
    }
    @else{
      .m-#{$side}-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
    
      .p-#{$side}-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}

.w-100{
    width: 100%;
}
.h-100-p{
    height: 100px;
}
