.flex-center {
  display: flex;
  justify-content: center;
}

.block {
  margin-top: 30px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%231761B9FF' stroke-width='4' stroke-dasharray='10%2c 30' stroke-dashoffset='40' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 100px;
  height: 130px;
  width: 130px;

  // plusIcon
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    color: #1761b9;
    position: absolute;
    margin-top: 170px;
  }
  &:hover {
    cursor: pointer;
  }
}
.plusIcon {
  color: #1761b9;
  font-size: 30px;
}
.contracts {
  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .contract-btn {
    text-transform: capitalize;
    font-size: 18px;
    border-radius: 6px;
  }
  .content-body {
    // padding: 2em;

    .contract-card {
      border-radius: 10px;
      padding: 20px 20px 10px 20px;
      .MuiIconButton-root{
        padding: 5px;
      }
      .MuiIconButton-root:hover{
        padding: 5px;
        color: #225A83;
      }
      background-color: rgba(4,125,216,.1);
      h4 {
        padding-bottom: 10px;
        font-weight: bold;
      }
      img {
        height: 70px;
      }
      .icons {
        display: flex;
        justify-content: space-between;
        .icon {
          padding: 5px;
          font-size: 30px;
          cursor: pointer;
        }
      }
      .file-name{
        line-height: 1.7;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .new-btn {
      outline: none;
      border-radius: 50%;
      height: 150px;
      width: 150px;
      background-color: #fff;
      border: 4px dashed #1761b9;
      // padding: 300px;
    }
    .block {
      margin-top: 30px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%231761B9FF' stroke-width='4' stroke-dasharray='10%2c 30' stroke-dashoffset='40' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 100px;
      height: 130px;
      width: 130px;

      // plusIcon
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        color: #1761b9;
        position: absolute;
        margin-top: 170px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .plusIcon {
      color: #1761b9;
      font-size: 30px;
    }
  }
  // .MuiToggleButton-root {
  //   border: 1px solid rgba(0, 0, 0, 0.12) !important;
  // }
  .MuiToggleButton-root.Mui-selected {
    background-color: #e4f1ff !important;
    color: #1761b9 !important;
    border: 2px solid #1761b9 !important;
  }
}

.contracts-list {
  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .contract-btn {
    text-transform: capitalize;
    font-size: 18px;
    border-radius: 6px;
  }
  .content-body {
    padding: 2em;
  }
  .table-header {
    background-color: #a5a4a4;
    font-weight: 900;
    text-align: center;
  }
  .bold-cell {
    font-weight: bold;

    .status {
      height: 12px;
      width: 12px;
      // margin-right: 7px;
      margin: 0 2px;

      border-radius: 10px;
    }
    .green {
      background-color: green;
    }
    .onhold {
      background-color: yellow;
    }
    .inactive {
      background-color: lightgray;
    }
  }
  .MuiTableCell-root {
    padding: 0 !important;
  }
}
