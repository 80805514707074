.pre-app-loader{
  background: linear-gradient(to bottom, #00b4d5, #00191e);
  font-size: 16px;
  height: 100%;
  width: 100%;

  .blob {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    height: 2rem;
    left: calc(50% - 1rem);
    position: absolute;
    top: calc(50% - 1rem);
    width: 2rem;
  }

  .blob-2 {
    -webkit-animation: animate-to-2 1.5s infinite;
    animation: animate-to-2 1.5s infinite;
  }

  .blob-3 {
    -webkit-animation: animate-to-3 1.5s infinite;
    animation: animate-to-3 1.5s infinite;
  }

  .blob-1 {
    -webkit-animation: animate-to-1 1.5s infinite;
    animation: animate-to-1 1.5s infinite;
  }

  .blob-4 {
    -webkit-animation: animate-to-4 1.5s infinite;
    animation: animate-to-4 1.5s infinite;
  }

  .blob-0 {
    -webkit-animation: animate-to-0 1.5s infinite;
    animation: animate-to-0 1.5s infinite;
  }

  .blob-5 {
    -webkit-animation: animate-to-5 1.5s infinite;
    animation: animate-to-5 1.5s infinite;
  }

  @-webkit-keyframes animate-to-2 {
    25%,
    75% {
      -webkit-transform: translateX(-1.5rem) scale(0.75);
      transform: translateX(-1.5rem) scale(0.75);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @keyframes animate-to-2 {
    25%,
    75% {
      -webkit-transform: translateX(-1.5rem) scale(0.75);
      transform: translateX(-1.5rem) scale(0.75);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @-webkit-keyframes animate-to-3 {
    25%,
    75% {
      -webkit-transform: translateX(1.5rem) scale(0.75);
      transform: translateX(1.5rem) scale(0.75);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @keyframes animate-to-3 {
    25%,
    75% {
      -webkit-transform: translateX(1.5rem) scale(0.75);
      transform: translateX(1.5rem) scale(0.75);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @-webkit-keyframes animate-to-1 {
    25% {
      -webkit-transform: translateX(-1.5rem) scale(0.75);
      transform: translateX(-1.5rem) scale(0.75);
    }
    50%,
    75% {
      -webkit-transform: translateX(-4.5rem) scale(0.6);
      transform: translateX(-4.5rem) scale(0.6);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @keyframes animate-to-1 {
    25% {
      -webkit-transform: translateX(-1.5rem) scale(0.75);
      transform: translateX(-1.5rem) scale(0.75);
    }
    50%,
    75% {
      -webkit-transform: translateX(-4.5rem) scale(0.6);
      transform: translateX(-4.5rem) scale(0.6);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @-webkit-keyframes animate-to-4 {
    25% {
      -webkit-transform: translateX(1.5rem) scale(0.75);
      transform: translateX(1.5rem) scale(0.75);
    }
    50%,
    75% {
      -webkit-transform: translateX(4.5rem) scale(0.6);
      transform: translateX(4.5rem) scale(0.6);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @keyframes animate-to-4 {
    25% {
      -webkit-transform: translateX(1.5rem) scale(0.75);
      transform: translateX(1.5rem) scale(0.75);
    }
    50%,
    75% {
      -webkit-transform: translateX(4.5rem) scale(0.6);
      transform: translateX(4.5rem) scale(0.6);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @-webkit-keyframes animate-to-0 {
    25% {
      -webkit-transform: translateX(-1.5rem) scale(0.75);
      transform: translateX(-1.5rem) scale(0.75);
    }
    50% {
      -webkit-transform: translateX(-4.5rem) scale(0.6);
      transform: translateX(-4.5rem) scale(0.6);
    }
    75% {
      -webkit-transform: translateX(-7.5rem) scale(0.5);
      transform: translateX(-7.5rem) scale(0.5);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @keyframes animate-to-0 {
    25% {
      -webkit-transform: translateX(-1.5rem) scale(0.75);
      transform: translateX(-1.5rem) scale(0.75);
    }
    50% {
      -webkit-transform: translateX(-4.5rem) scale(0.6);
      transform: translateX(-4.5rem) scale(0.6);
    }
    75% {
      -webkit-transform: translateX(-7.5rem) scale(0.5);
      transform: translateX(-7.5rem) scale(0.5);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @-webkit-keyframes animate-to-5 {
    25% {
      -webkit-transform: translateX(1.5rem) scale(0.75);
      transform: translateX(1.5rem) scale(0.75);
    }
    50% {
      -webkit-transform: translateX(4.5rem) scale(0.6);
      transform: translateX(4.5rem) scale(0.6);
    }
    75% {
      -webkit-transform: translateX(7.5rem) scale(0.5);
      transform: translateX(7.5rem) scale(0.5);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

  @keyframes animate-to-5 {
    25% {
      -webkit-transform: translateX(1.5rem) scale(0.75);
      transform: translateX(1.5rem) scale(0.75);
    }
    50% {
      -webkit-transform: translateX(4.5rem) scale(0.6);
      transform: translateX(4.5rem) scale(0.6);
    }
    75% {
      -webkit-transform: translateX(7.5rem) scale(0.5);
      transform: translateX(7.5rem) scale(0.5);
    }
    95% {
      -webkit-transform: translateX(0) scale(1);
      transform: translateX(0) scale(1);
    }
  }

}
.loader-main {
  display: flex;
  width: calc(100% + 8px);
  justify-content: center;
  align-items: center;
  height: 100vh;
  & > * + * {
    margin-left: 16px;
  }
}