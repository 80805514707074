:root{
    --gray: #f6f6fc;
    --ball-blue:#222222;
    --white:#fff;
    --black:#000000;
    --peridot:#E6DB74;
}
:app-dark{

}
:app-light{
    --gray: #f6f6fc;
    --ball-blue:#222222;
    --white:#fff;
    --black:#000000;
}

.app-dashboard-cards{
    position: relative;
    width:100%;
    min-height: 30vh;
    max-width: 400px;
}
h5{
    text-transform: capitalize;
}

.forgot-password{
    background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: 'cover';
}


